
import { Component } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import BackButton from "@/components/BackButton.vue";
import ReservationModule from "@/store/reservation";

@Component({
  name: "ReservationSuccess",
  components: {
    Header,
    Footer,
    BackButton,
  },
})
export default class ReservationSuccess extends CheckCommonLogin {
  private mounted() {
    ReservationModule.reservationReset();
  }
}
